import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import { Page, Footer } from "../components";

import * as css from "../styles/pages/index.module.scss";
// markup
const IndexPage = () => {
  return (
    <Page metaTitle="Odkrywaj nieznane wokół siebie! - Beautiful Places">
      <Helmet>
        <meta name="title" content="Odkrywaj nieznane wokół siebie!" />
        <meta
          name="description"
          content="Niezależnie gdzie jesteś, aplikacja Beautiful Places zawsze pokaże Ci szczególne miejsca warte zobaczenia."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://beautifulplaces.top/" />
        <meta property="og:title" content="Odkrywaj nieznane wokół siebie!" />
        <meta
          property="og:description"
          content="Niezależnie gdzie jesteś, aplikacja Beautiful Places zawsze pokaże Ci szczególne miejsca warte zobaczenia."
        />
        <meta
          property="og:image"
          content="https://beautifulplaces.top/metatags.jpg"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://beautifulplaces.top/" />
        <meta
          property="twitter:title"
          content="Odkrywaj nieznane wokół siebie!"
        />
        <meta
          property="twitter:description"
          content="Niezależnie gdzie jesteś, aplikacja Beautiful Places zawsze pokaże Ci szczególne miejsca warte zobaczenia."
        />
        <meta
          property="twitter:image"
          content="https://beautifulplaces.top/metatags.jpg"
        />
      </Helmet>
      <section className={css.banner}>
        <main className={`${css.main} ${css.main_banner}`}>
          <h1 className={css.title}>Odkrywaj nieznane wokół siebie!</h1>
          <h2 className={css.text}>
            Jeszcze nigdy nie odkryłeś tylu wspaniałych miejsc wokół siebie!
          </h2>
          <a href="#download" className={css.cta} data-should-close>
            Wypróbuj za darmo
          </a>
        </main>
        <div className={css.banner_image}>
          <StaticImage
            src="../images/beautiful-places-app.png"
            alt="Beautiful Places App"
            style={{ zIndex: 2 }}
          />
        </div>
      </section>
      <section
        id="about-us"
        className={`${css.section} ${css.section_always_at_hand}`}
      >
        <div
          className={`${css.section_background} ${css.section_background_always_at_hand}`}
        >
          <StaticImage
            src="../images/phones.png"
            alt="Beautiful Places on android"
          />
        </div>
        <main className={css.main}>
          <h2 className={css.title}>Beautiful places zawsze pod ręką</h2>
          <p className={css.text}>
            Czegokolwiek szukasz, gdziekolwiek chciałbyś spędzić swój wolny
            czas, z aplikacją Beautiful places możesz to zrobić! Niezależnie
            gdzie jesteś, aplikacja Beautiful places zawsze pokaże Ci szczególne
            miejsca warte zobaczenia.
          </p>
        </main>
      </section>
      <section className={`${css.section} ${css.section_about_us}`}>
        <main className={css.main}>
          <h2 className={css.title}>Dziel się ciekawymi miejscami z innymi</h2>
          <p className={css.text}>
            Niesamowita formacja skalna? Zapierające dech w piersiach graffiti?
            A może miejsce idealne do zabawy z dziećmi? Dzięki naszej aplikacji
            możesz nie tylko odkrywać nowe ciekawe miejsca, ale także dzielić
            się nimi z innymi użytkownikami.
          </p>
        </main>
        <div className={css.section_background}>
          <StaticImage
            src="../images/share-with-others.png"
            alt="Beautiful Places on android"
          />
        </div>
      </section>
      <section
        id="discover"
        className={`${css.section} ${css.section_discover}`}
      >
        <div
          className={`${css.section_background} ${css.section_background_discover}`}
        >
          <StaticImage
            src="../images/discover.png"
            alt="Beautiful Places on android"
          />
        </div>
        <main className={css.main}>
          <h2 className={css.title}>
            Odkrywaj nowe miejsca łatwo i intuicyjnie
          </h2>
          <p className={css.text}>
            Wystarczy tylko kilka kliknięć żeby odkrywać ciekawe atrakcje w
            Twoim pobliżu. Dzięki Beautiful places możesz szybko i łatwo znaleźć
            prawdziwe perełki w okolicy miejsca, w którym się znajdujesz.
          </p>
        </main>
      </section>
      <section
        id="download"
        className={`${css.section} ${css.section_download}`}
      >
        <main className={`${css.main} ${css.main_download}`}>
          <h2 className={css.title} style={{ color: "#fff" }}>
            Pobranie aplikacji Beautiful Places zajmie Ci tylko minutę!
          </h2>
          <p className={css.text} style={{ color: "#fff" }}>
            <span>
              Z nami odkryjesz tysiące niesamowitych miejsc. Pobierz, korzystaj
              i ciesz się!
            </span>
            <Link
              to="https://play.google.com/store/apps/details?id=com.smartweb.beautifulplaces"
              title="Download Beautiful Places for android"
            >
              <StaticImage
                src="../images/google.png"
                alt="Download Beautiful Places for android"
              />
            </Link>
          </p>
        </main>
        <div
          className={`${css.section_background} ${css.section_background_download}`}
        >
          <StaticImage
            src="../images/play.png"
            alt="Beautiful Places on android"
          />
        </div>
      </section>
      <Footer />
    </Page>
  );
};

export default IndexPage;
