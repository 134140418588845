// extracted by mini-css-extract-plugin
export var banner = "index-module--banner--rsPiu";
export var banner_image = "index-module--banner_image--xi248";
export var cta = "index-module--cta--LeHgI";
export var main = "index-module--main--8OG11";
export var main_banner = "index-module--main_banner--p9ht-";
export var main_download = "index-module--main_download--VGHDY";
export var section = "index-module--section--ec5MD";
export var section_about_us = "index-module--section_about_us--CEK4P";
export var section_always_at_hand = "index-module--section_always_at_hand--J0JDO";
export var section_background = "index-module--section_background--vNtrf";
export var section_background_always_at_hand = "index-module--section_background_always_at_hand--yZlqW";
export var section_background_discover = "index-module--section_background_discover--L4Lk1";
export var section_background_download = "index-module--section_background_download--0H5Qk";
export var section_discover = "index-module--section_discover--RjJNo";
export var section_download = "index-module--section_download--tCNq9";
export var text = "index-module--text--JE1h+";
export var title = "index-module--title--oUmHu";